import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StorageService } from 'src/app/_services/front/storage.service';
import { SwireConstants } from 'src/app/_services/front/constants';
import { Router, ActivatedRoute } from '@angular/router';

@Injectable()
export class UserService {

  getUserListDataUrl: string = "api/v1/user/getUserList";
  getCustomerListDataUrl: string = "api/v1/user/getCustomerList";
  invitationLinkUrl: string = "api/v1/user/sendInvitation";
  saveUserAccountsUrl: string = "api/v1/user/addCustomerToMap";
  saveUserGroupUrl: string = "api/v1/user/addCustomerGroupToMap";
  searchCustomerAccountsUrl: string = "api/v1/user/searchCustomer";
  customerCountUrl: string = "api/v1/user/getCustomerCount";
  activationLinkUrl: string = "api/v1/user/sendUserActivationLink";
  deactivateUserUrl: string = "api/v1/user/deActivateUser";
  updateUserRoleUrl: string = "api/v1/user/updateUserRole";
  updateUserProfileUrl: string = "api/v1/user/updateUserProfile";

  checkContactUrl: string = "api/v1/user/checkContact";
  updateContactUrl: string = "api/v1/user/updateContactNumber";
  verifyEmailUrl: string = "api/v1/user/verifyEmailChangeContact";
  verifyPhoneUrl: string = "api/v1/user/verifyPhoneForChnageContact";
  sendVerificationCodeforChangePhoneNumberUrl: string = "api/v1/user/sendVerificationCodeforChangePhoneNumber";
  resetOTPDataUrl: string = "api/v1/user/resetValues";
  checkEmailUrl: string = "api/v1/user/checkEmailExistOrNot";
  sendPhoneVerificationCodeForEmailUrl: string = "api/v1/user/sendVerificationCodeforChangeEmailId";
  verifyPhoneForChnageEmailUrl: string = "api/v1/user/verifyPhoneForChangeEmail";
  verifyEmailForChnageEmailUrl: string = "api/v1/user/verifyEmailChangeEmail";
  updateEmailUrl: string = "api/v1/user/updateEmailId";
  resetValuesForEmailUrl: string = "api/v1/user/resetValuesForEmail";
  checkContactForManageUrl: string = "api/v1/user/checkContactForManage";
  sendOtpCodeforManagePhoneNumberUrl: string = "api/v1/user/sendOtpCodeforManagePhoneNumber";
  verifyEmailForManageContactUrl: string = "api/v1/user/verifyEmailForManageContact";
  updatePhoneNumberUrl: string = "api/v1/user/updatePhoneNumber";
  getInterestOfServiceUrl: string = "";
  checkUserLoginCookieeUrl: string = "api/v1/auth/checkUserLoginCookiee";
  deleteUserLoginCookieeUrl: string = "api/v1/user/deleteUserLoginCookiee";
  deleteUserProfileImageUrl: string = "api/v1/user/deleteUserProfileImage"
  userTokenExpieryTimeUrl: string = "api/v1/user/getUserTokenExpieryTime";
  deleteUserAccessTokenUrl: string = "api/v1/auth/deleteUserAccessToken";
  deleteUserAccessTokenByIdUrl: string = "api/v1/user/deleteUserAccessTokenById"
  addUserAccessTokenUrl: string = "api/v1/user/addUserAccessToken";
  validateAccessTokenforSessionUrl: string = "api/v1/user/validateAccessTokenforSession";
  staySignInUrl: string = "api/v1/user/staySignIn";
  getAdminUserListDataUrl: string = "api/v1/user/getAdminUserList";
  getMappingUserUrl: string = "api/v1/user/getMappingUserList";
  getCustomerGroupListDataUrl: string = "api/v1/user/getCustomerGroupList";
  updateCustomerAuthStatus: string = "api/v1/user/updateCustomerStatus";
  getMappedCodeForUser: string = "api/v1/user/getMappedCodeForUser";

  constructor(private http: HttpClient,
    private _router: Router,
    private _storageService: StorageService,
    private _swireConstant: SwireConstants) { }

  getUserList() {
    let responseType: any = {
      responseType: 'json'
    }
    return this.http.get(this._swireConstant.apiCallUrl + this.getUserListDataUrl, responseType);
  }

  getCustomerList(postData) {
    let responseType: any = {
      responseType: 'json'
    }
    return this.http.post(this._swireConstant.apiCallUrl + this.getCustomerListDataUrl, postData, responseType);
  }

  getCustomerGroupList(postData) {
    let responseType: any = {
      responseType: 'json'
    }
    return this.http.post(this._swireConstant.apiCallUrl + this.getCustomerGroupListDataUrl, postData, responseType);
  }

  getAdminUserList() {
    let responseType: any = {
      responseType: 'json'
    }
    return this.http.get(this._swireConstant.apiCallUrl + this.getAdminUserListDataUrl, responseType);
  }
  getMappingUserList(postData) {
    let responseType: any = {
      responseType: 'json'
    }
    return this.http.post(this._swireConstant.apiCallUrl + this.getMappingUserUrl, postData, responseType);
  }

  sendActivationLink(postData) {
    let responseType: any = {
      responseType: 'json'
    }
    return this.http.post(this._swireConstant.apiCallUrl + this.activationLinkUrl, postData, responseType);
  }

  sendInvitationLink(postData) {
    let responseType: any = {
      responseType: 'json'
    }
    return this.http.post(this._swireConstant.apiCallUrl + this.invitationLinkUrl, postData, responseType);
  }

  saveUserAccounts(postData) {
    let responseType: any = {
      responseType: 'json'
    }
    return this.http.post(this._swireConstant.apiCallUrl + this.saveUserAccountsUrl, postData, responseType);
  }

  saveUserGroup(postData) {
    let responseType: any = {
      responseType: 'json'
    }
    return this.http.post(this._swireConstant.apiCallUrl + this.saveUserGroupUrl, postData, responseType);
  }

  searchCustomerAccounts(postData) {
    let responseType: any = {
      responseType: 'json'
    }
    return this.http.post(this._swireConstant.apiCallUrl + this.searchCustomerAccountsUrl, postData, responseType);
  }

  getCustomerCount(postData) {
    let responseType: any = {
      responseType: 'json'
    }
    return this.http.post(this._swireConstant.apiCallUrl + this.customerCountUrl, postData, responseType);
  }

  deactivateUser(postData) {
    let responseType: any = {
      responseType: 'json'
    }
    return this.http.post(this._swireConstant.apiCallUrl + this.deactivateUserUrl, postData, responseType);
  }

  updateUserRole(postData) {
    let responseType: any = {
      responseType: 'json'
    }
    return this.http.post(this._swireConstant.apiCallUrl + this.updateUserRoleUrl, postData, responseType);
  }

  checkContact(postData) {
    let responseType: any = {
      responseType: 'json'
    }
    return this.http.post(this._swireConstant.apiCallUrl + this.checkContactUrl, postData, responseType);
  }

  sendVerificationCodeforChangePhoneNumber(postData) {
    let responseType: any = {
      responseType: 'json'
    }
    return this.http.post(this._swireConstant.apiCallUrl + this.sendVerificationCodeforChangePhoneNumberUrl, postData, responseType);

  }

  updateContact(postData) {
    let responseType: any = {
      responseType: 'json'
    }
    return this.http.post(this._swireConstant.apiCallUrl + this.updateContactUrl, postData, responseType);
  }

  verifyEmail(postData) {
    let responseType: any = {
      responseType: 'json'
    }
    return this.http.post(this._swireConstant.apiCallUrl + this.verifyEmailUrl, postData, responseType);
  }

  verifyPhone(postData) {
    let responseType: any = {
      responseType: 'json'
    }
    return this.http.post(this._swireConstant.apiCallUrl + this.verifyPhoneUrl, postData, responseType);
  }

  resetValues(postData) {
    let responseType: any = {
      responseType: 'json'
    }
    return this.http.post(this._swireConstant.apiCallUrl + this.resetOTPDataUrl, postData, responseType);
  }

  checkEmail(postData) {
    let responseType: any = {
      responseType: 'json'
    }
    return this.http.post(this._swireConstant.apiCallUrl + this.checkEmailUrl, postData, responseType);
  }

  sendPhoneVerificationCodeForEmail(postData) {
    let responseType: any = {
      responseType: 'json'
    }
    return this.http.post(this._swireConstant.apiCallUrl + this.sendPhoneVerificationCodeForEmailUrl, postData, responseType);
  }
  verifyPhoneForChnageEmail(postData) {
    let responseType: any = {
      responseType: 'json'
    }
    return this.http.post(this._swireConstant.apiCallUrl + this.verifyPhoneForChnageEmailUrl, postData, responseType);

  }

  verifyEmailForChnageEmail(postData) {
    let responseType: any = {
      responseType: 'json'
    }
    return this.http.post(this._swireConstant.apiCallUrl + this.verifyEmailForChnageEmailUrl, postData, responseType);
  }

  updateEmail(postData) {
    let responseType: any = {
      responseType: 'json'
    }
    return this.http.post(this._swireConstant.apiCallUrl + this.updateEmailUrl, postData, responseType);
  }

  resetValuesForEmail(postData) {
    let responseType: any = {
      responseType: 'json'
    }
    return this.http.post(this._swireConstant.apiCallUrl + this.resetValuesForEmailUrl, postData, responseType);
  }

  checkContactForManage(postData) {
    let responseType: any = {
      responseType: 'json'
    }
    return this.http.post(this._swireConstant.apiCallUrl + this.checkContactForManageUrl, postData, responseType);

  }

  sendOtpCodeforManagePhoneNumber(postData) {
    let responseType: any = {
      responseType: 'json'
    }
    return this.http.post(this._swireConstant.apiCallUrl + this.sendOtpCodeforManagePhoneNumberUrl, postData, responseType);
  }

  verifyEmailForManageContact(postData) {
    let responseType: any = {
      responseType: 'json'
    }
    return this.http.post(this._swireConstant.apiCallUrl + this.verifyEmailForManageContactUrl, postData, responseType);
  }

  updatePhoneNumber(postData) {
    let responseType: any = {
      responseType: 'json'
    }
    return this.http.post(this._swireConstant.apiCallUrl + this.updatePhoneNumberUrl, postData, responseType);
  }

  checkUserLoginCookiee(postData) {
    let responseType: any = {
      responseType: 'json'
    }
    return this.http.post(this._swireConstant.apiCallUrl + this.checkUserLoginCookieeUrl, postData, responseType);
  }

  deleteUserLoginCookiee(postData) {
    let responseType: any = {
      responseType: 'json'
    }
    return this.http.post(this._swireConstant.apiCallUrl + this.deleteUserLoginCookieeUrl, postData, responseType);
  }

  updateUserProfileData(postData) {
    let responseType: any = {
      responseType: 'json'
    }
    return this.http.post(this._swireConstant.apiCallUrl + this.updateUserProfileUrl, postData, responseType);
  }

  getInterestOfService() {
    let responseType: any = {
      responseType: 'json'
    }
    return this.http.get(this._swireConstant.apiCallUrl + this.getInterestOfServiceUrl, responseType);
  }

  deleteUserProfileImage(postData) {
    let responseType: any = {
      responseType: 'json'
    }
    return this.http.post(this._swireConstant.apiCallUrl + this.deleteUserProfileImageUrl, postData, responseType);
  }

  getUserTokenExpieryTime() {
    let responseType: any = {
      responseType: 'json'
    }
    return this.http.get(this._swireConstant.apiCallUrl + this.userTokenExpieryTimeUrl, responseType);
  }

  addUserAccessToken() {
    let responseType: any = {
      responseType: 'json'
    }
    return this.http.post(this._swireConstant.apiCallUrl + this.addUserAccessTokenUrl, responseType);
  }

  deleteUserAccessToken() {
    let responseType: any = {
      responseType: 'json'
    }
    return this.http.post(this._swireConstant.apiCallUrl + this.deleteUserAccessTokenUrl, responseType);
  }

  validateAccessTokenforSession() {
    let responseType: any = {
      responseType: 'json'
    }
    return this.http.post(this._swireConstant.apiCallUrl + this.validateAccessTokenforSessionUrl, responseType);
  }

  deleteUserAccessTokenById() {
    let responseType: any = {
      responseType: 'json'
    }
    return this.http.post(this._swireConstant.apiCallUrl + this.deleteUserAccessTokenByIdUrl, responseType);
  }

  staySignIn() {
    let responseType: any = {
      responseType: 'json'
    }
    return this.http.get(this._swireConstant.apiCallUrl + this.staySignInUrl, responseType);
  }
  updateCustomerStatus(postData) {
    let responseType: any = {
      responseType: 'json'
    }
    return this.http.post(this._swireConstant.apiCallUrl + this.updateCustomerAuthStatus, postData, responseType);
  }
  getMappedCodeForLoggedUser() {
    let responseType: any = {
      responseType: 'json'
    }
    return this.http.get(this._swireConstant.apiCallUrl + this.getMappedCodeForUser, responseType);
  }
}
