import { environment } from "src/environments/environment";
import { AppConfig } from 'src/app/app.config';
import { Injectable } from "@angular/core";

@Injectable()
export class SwireConstants {
  protected apiServer = AppConfig.settings.apiServer;
  protected appEnviroment = AppConfig.settings.env;

  swireNewsUrl: string = 'http://local.swirenews.com/';
  appEnv: string = 'LOCAL';
  apiCallUrl: String = '';
  pdfBaseUrl: String = '';
  googleTagKey: String = '';
  googleGTMKey: String = '';
  AWAccountID: String = '';
  productbaseUrl: String = '';
  ZendDeskKey:String = '';
  totalFileSize:any = 15;
  maxFileSize:any = 5;

  constructor() {
    this.checkAppEnv();
    this.setApiSettings();
  }

  setApiSettings(){
    this.appEnv = this.appEnviroment.name;
    this.apiCallUrl = this.apiServer.baseUrl;
    this.pdfBaseUrl = environment.PDF_BASE_URL;
    this.productbaseUrl = environment.PRODUCT_BASE_URL;
    this.swireNewsUrl = environment.SWIRE_NEWS_URL;
    this.googleTagKey = environment.GOOGLE_TAG_KEY;
    this.googleGTMKey = environment.GOOGLE_GTM_KEY;
    this.AWAccountID = environment.GOOGLE_ADS_ACCOUNT_ID;
  }

  validateServerEnv(){
    let szHostName = window.location.hostname;
    if(szHostName=='localhost'){
      return true;
    } else if(szHostName=='nsl-fe-dev.swiredigital-s3-staging.com') {
      return true;
    } else if(szHostName=='nsl-staging.swireshippingdigital.com') {
      return true;
    } else if(szHostName=='nsl.swireshippingdigital.com' || szHostName=='nsl.swireshippingdigital.com' || szHostName=='prelive.naurushippingline.com' || szHostName=='naurushippingline.com' || szHostName=='www.naurushippingline.com') {
      return true;
    } else {
      return false;
    }
  }

  checkAppEnv(){
    let szHostName = window.location.hostname;

    if(szHostName=='localhost')
    {
      this.ZendDeskKey = "6b72b56a-2b4e-4fdf-9e87-acfa555b4cec";
    }
    if(szHostName=='ecomdev.swiredigital-s3-staging.com' || szHostName=='ecomtrack.swiredigital-s3-staging.com' || szHostName=='prerender.swiredigital-s3-staging.com' || szHostName=='ecomtrack.swiredigital-s3-staging.com:90')
    {
      this.ZendDeskKey = "6d82d752-7170-4ecd-b748-8c70e4026dba";
    }
    else if(szHostName=='staging.swireshipping.com')
    {
      this.ZendDeskKey = "67d3b359-678d-42a4-808b-cbe7d843c6c9";
    }
    else if(szHostName=='www.swireshipping.com' || szHostName=='swireshipping.com' || szHostName=='prelive.naurushippingline.com' || szHostName=='naurushippingline.com' || szHostName=='www.naurushippingline.com')
    {
      this.ZendDeskKey = "67d3b359-678d-42a4-808b-cbe7d843c6c9";
    }else{
      // this.appEnv = "production";
      // this.swireNewsUrl = "https://news.swireshipping.com/";
      // this.googleTagKey = 'UA-151746595-1';
      // this.googleGTMKey = 'GTM-P6MXN2F';
    }
  }
  getTotalFileSize(){
    return this.totalFileSize * 1024 * 1024;
  }
}

