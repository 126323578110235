import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { StorageService } from 'src/app/_services/front/storage.service';
import { DataService } from 'src/app/_services/front/data.service';
import { Router } from '@angular/router';
// import { NgProgress } from 'ngx-progressbar';
import { CustomHelper } from 'src/app/helpers/custom';

@Component({
    selector: "app-auth",
    templateUrl: "./auth.component.html",
    styleUrls: ['./auth.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AuthComponent implements OnInit {

    
    constructor(private _storageService: StorageService,
        private _dataService: DataService,
        private _router: Router,
        // public ngProgress: NgProgress
        ) { 
    }
    ngOnInit() {
        // this.ngProgress.start();
        let szSecretKey = CustomHelper.getRandomString(); 
        this._dataService.sendTokenRequest('auth/getAuthtoken', szSecretKey).subscribe(
            (result) => {
                if(result['status'] == 'OK'){
                    let dataValidUptoTime = (new Date().setMinutes(480));
                    this._storageService.putDataInStorage('access_token', result['data']['jwtToken']);
                    this._storageService.putDataInStorage('access_token_expiry', dataValidUptoTime);
                    this._storageService.putDataInStorage('access_token_payload', szSecretKey); 
                    let urlToRedirect:any = this._storageService.getDataFromStorage('current_url');
                    // this._storageService.removeDataFromStorage('current_url');
                    
                    if(urlToRedirect != '/auth'){
                        this._router.navigate([decodeURIComponent(urlToRedirect)]);
                    }else{
                        this._router.navigate(['/']);
                    }
                }else{
                    this._router.navigate(['/error']);
                }
            },
            error => {
                this._router.navigate(['/error']);
            }
        );
    }

}