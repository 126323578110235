import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ErrorComponent } from 'src/app/front/shared/error/error.component';
import { NotFoundComponent } from 'src/app/front/pages/not-found/not-found.component';
import { AuthComponent } from 'src/app/auth/auth.component';
const routes: Routes = [
    {
        "path": "error",
        "component": ErrorComponent
    },
    {
        "path": "404",
        "component": NotFoundComponent
    },
    {
      "path": "auth",
      "component": AuthComponent
    },
    { path: 'localization', loadChildren: () => import('./localization/localization.module').then(m => m.LocalizationModule) }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
 