export const ConfigEnvStage = {
    "env": {
        "name": "staging"
    },
    "staticVariables": {
        "headerLogo": "../../../../assets/app/media/img/logos/header-logo.svg",
        "innerHeaderLogo": "../../../../assets/app/media/img/logos/header-logo-result.svg"
    },
    "apiServer": {
        "baseUrl": "/backend/"
    }
}
