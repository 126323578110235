import { Injectable } from '@angular/core';
import { IAppConfig } from 'src/app/app-config.model';
import { ConfigEnvDev } from 'src/app/config/config.env-dev';
import { ConfigEnvStage } from 'src/app/config/config.env-stage';
import { ConfigEnvProd } from 'src/app/config/config.env-prod';
import { ConfigEnvLocal } from 'src/app/config/config.env-local';

@Injectable()
export class AppConfig {
    static settings: IAppConfig;
    constructor() {}
    load() {
        return new Promise<void>((resolve, reject) => {
            let szHostName = window.location.hostname;
            if(szHostName=='localhost'){
                AppConfig.settings = <IAppConfig>ConfigEnvLocal;
            } else if(szHostName=='nsl-fe-dev.swiredigital-s3-staging.com' ) {
                AppConfig.settings = <IAppConfig>ConfigEnvDev;
            } else if(szHostName=='nsl-staging.swireshippingdigital.com') {
                AppConfig.settings = <IAppConfig>ConfigEnvStage;
            } else if(szHostName=='nsl.swireshippingdigital.com' || szHostName=='prelive.naurushippingline.com' || szHostName=='naurushippingline.com' || szHostName=='www.naurushippingline.com') {
                AppConfig.settings = <IAppConfig>ConfigEnvProd;
            } else {
                AppConfig.settings = <IAppConfig>ConfigEnvLocal;
            }
            resolve();
        });
    }
}
