import { Injectable } from '@angular/core';
import { DataService } from 'src/app/_services/front/data.service';
import { StorageService } from 'src/app/_services/front/storage.service';

@Injectable({
  providedIn: 'root' 
})
export class CustomAutoCompleteService {

  dataAry:any;

  constructor(private _dataService:DataService,
              private _storageService: StorageService) 
  { }

  getResults() {
    return this._dataService.sendGetRequest('tradeSearch');
  }
  
}
