import { Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LOCATION_INITIALIZED } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

export function ApplicationInitializerFactory(
  translate: TranslateService, injector: Injector) {
  let currUrl = window.location.href;
  return async () => {
    if (currUrl.match(/zh-hans/gi)) {
      await localStorage.setItem('defaultLang', 'ch');
      await localStorage.setItem('current_url', '/ch');
    } else {
      // await localStorage.setItem('defaultLang', 'en');
    } 
    // else {
    //   await localStorage.setItem('defaultLang', 'en');
    // }
    await injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
    // added for browser lang
    let arr = ['zh-CN','zh','zh-TW','zh-HK','ZH_hans'];   
      let userLang = navigator.language; 
      const browserLang = translate.getBrowserLang();
      let bLang = arr.find(o=>o==browserLang)
    // added for browser lang
    if (!localStorage.getItem('defaultLang')) {
      await localStorage.setItem('defaultLang',(bLang? 'ch':'en'))
    }
    const deaultLang = localStorage.getItem('defaultLang');
    translate.addLangs(['en', 'ch']);
    translate.setDefaultLang(deaultLang);
    try {
      await translate.use(deaultLang).toPromise();
    } catch (err) {
    }
  };
}