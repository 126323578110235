import { HttpClient, HttpHeaders} from "@angular/common/http";
import { SwireConstants } from 'src/app/_services/front/constants';
import { StorageService } from 'src/app/_services/front/storage.service';
import { Router } from '@angular/router';
import { timeout, catchError } from 'rxjs/operators';
import { Injectable } from "@angular/core";

@Injectable()
export class DataService {

    constructor(private _http: HttpClient,
                private _swireConstant: SwireConstants,
                private _storageService: StorageService,
    ) {}

    sendPostRequest(requestUrl: string, postData: any, responseType:any = {
         responseType: 'json' //magic
      }) {
        responseType.headers = "x-access-token:" + this._storageService.getDataFromStorage('access_token');        
        return this._http.post(this._swireConstant.apiCallUrl + 'api/v1/' + requestUrl, postData, responseType); 
       
    }

    sendGetRequest(requestUrl:string) {
        
        let requestHeader:any = {
            headers: "x-access-token: " + this._storageService.getDataFromStorage('access_token')
        };
         
        let szToken = this._storageService.getDataFromStorage('access_token');
        let arHeaders = new HttpHeaders({'x-access-token': szToken });
        let options = { "headers": arHeaders };
        if(szToken){
            return this._http.get(this._swireConstant.apiCallUrl + 'api/v1/' +  requestUrl, options);
        } else {
            return this._http.get(this._swireConstant.apiCallUrl + 'api/v1/' +  requestUrl);
        }
    }

    sendTokenRequest(requestUrl:string, szSecretKey:string) { 
        let postData = {
            "payload": szSecretKey
        };
        return this._http.post(this._swireConstant.apiCallUrl + requestUrl, postData);   
    }

    sendGetRequestWithParam(requestUrl:string,params) {
        let options = {};
        let szToken = this._storageService.getDataFromStorage('access_token');
        let arHeaders = new HttpHeaders({'x-access-token': szToken });
        if(szToken){
            options['headers'] = arHeaders;
        }
        if(params){
            options['params'] = params;
        }
        
        return this._http.get(this._swireConstant.apiCallUrl + 'api/v1/' +  requestUrl, options);
    }

}
