import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, Injector } from '@angular/core';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ScriptLoaderService } from "./_services/front/script-loader.service";

import { AuthInterceptor } from "./auth.interceptor";
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from "@angular/common/http";
import { ErrorComponent } from 'src/app/front/shared/error/error.component';
import { NotFoundComponent } from 'src/app/front/pages/not-found/not-found.component';

import { AuthComponent } from 'src/app/auth/auth.component';
import { DataService } from "src/app/_services/front/data.service";
import { SwireConstants } from "src/app/_services/front/constants";
import { CustomAutoCompleteService } from "src/app/_services/front/custom-auto-complete.service";
import { StorageService } from 'src/app/_services/front/storage.service';
import { UserService } from 'src/app/_services/front/user.service';
import { PageTrackingService } from 'src/app/_services/front/page-tracking.service';
import { ValidationService } from 'src/app/helpers/validateService';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { WindowRef } from 'src/app/utils/WindowRef';
import { HttpLoaderFactory , ApplicationInitializerFactory } from './config/translation.config';
import { AppConfig } from 'src/app/app.config';
import { AuthGuard } from 'src/app/auth/_guards/auth.guard';
import { LocalizationRoutingModule } from './localization/localization-routing.module';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

export function initializeApp(appConfig: AppConfig) {
  return () => appConfig.load();
}

@NgModule({
  declarations: [
    AppComponent,
    ErrorComponent,
    NotFoundComponent,
    AuthComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    LocalizationRoutingModule,
    // FrontRoutingModule,
    HttpClientModule,
    // NgProgressModule,
    // TagInputModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgbModule
  ],
  providers: [
    ScriptLoaderService,
    Title,
    DataService,
    SwireConstants,
    CustomAutoCompleteService,
    StorageService,
    PageTrackingService,
    UserService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: ApplicationInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true
    },
    ValidationService,
    ReactiveFormsModule,
    FormsModule,
    WindowRef,
    AuthGuard,
    // BnNgIdleService,
    AppConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppConfig],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
