import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route, CanLoad, CanActivateChild } from '@angular/router';
import { StorageService } from 'src/app/_services/front/storage.service';

@Injectable({
  providedIn: 'root'
})
export class UrlMultilangugalService {

  constructor(
    public _storageService: StorageService,
    public _router: Router
  ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let url = state.url;
    return this.checkUrl(url);
  }

  canLoad(route: Route) {
    let url = route.path;
    return this.checkUrl(url);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    return this.canActivate(route, state);
  }
  checkUrl(url: string) {
    let documentReferer = window.document.referrer;
    if(url == '/' && documentReferer.indexOf(window.document.domain) < 0){
      // this._storageService.putDataInStorage("defaultLang", 'en');
      // this._router.navigate(['/']);
      // return false;
    }

    if (this._storageService.getDataFromStorage('defaultLang') && this._storageService.getDataFromStorage('defaultLang') == 'ch') {
      if (url.indexOf("zh-hans") < 0) {
        this._router.navigate(['/zh-hans/'+ url]);
        return false;
      } else {
      }
    }
    if (this._storageService.getDataFromStorage('defaultLang') && this._storageService.getDataFromStorage('defaultLang') == 'en') {
      if (url.indexOf("zh-hans") > -1) {
        url.replace('/zh-hans', '');
        this._router.navigate([url]);
        return false;
      }
    }
    return true;
  }
}
