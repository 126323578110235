import { Component, OnInit, ViewEncapsulation, HostBinding, HostListener } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { Observable } from 'rxjs';
import { WindowRef } from 'src/app/utils/WindowRef';
import { SwireConstants } from 'src/app/_services/front/constants';
import { ScriptLoaderService } from 'src/app/_services/front/script-loader.service';
import { TranslateService } from '@ngx-translate/core';

declare let ga: Function;

@Component({
    selector: 'body',
    templateUrl: './app.component.html',
    encapsulation: ViewEncapsulation.None,
})

export class AppComponent implements OnInit {

    title = 'app';

    userType: string = '';
    environment: any = '';

    $isLoggedIn: Observable<boolean>;

    @HostBinding('class.application') class = 'application';

    globalBodyClass = '';

    constructor(
        private _router: Router,
        public translate: TranslateService,
        private _windowRef: WindowRef,
        private _swireConstants: SwireConstants,
        private _script: ScriptLoaderService


    ) {
        if (!this._swireConstants.validateServerEnv()) {
            this._router.navigate(['/404']);
        }
        
        this._windowRef.nativeWindow.UATagID = this._swireConstants.googleTagKey;
        this._windowRef.nativeWindow.GTMKey = this._swireConstants.googleGTMKey;
        this._windowRef.nativeWindow.AWAccountID = this._swireConstants.AWAccountID;

        this.environment = this._swireConstants.appEnv;
        // subscribe to router events and send page views to Google Analytics
        this._script.loadScripts('head', [
            'https://www.googletagmanager.com/gtag/js?id=' + this._swireConstants.googleTagKey,
            './assets/app/js/google-tag.js'
        ], false).then(() => {

            this._router.events.subscribe(event => {

                if (event instanceof NavigationEnd) {
                    ga('set', 'page', event.urlAfterRedirects);
                    ga('send', 'pageview');
                }

            });
        });
        $('body').append('<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=' + this._swireConstants.googleGTMKey + '" height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>');
    }

    ngOnInit() {
        this._router.events.subscribe((route) => {
            let timeStart: any = '';
            if (route instanceof NavigationStart) {
                if (this._windowRef.nativeWindow.iResetStatTime == 1) {
                    let dtDate = new Date();
                    this._windowRef.nativeWindow.pageLoadStartTime = dtDate.getTime();
                }
            }
            if (route instanceof NavigationEnd) {
                window.scroll({
                    top: 0,
                    left: 0
                });
            }
        });
    }

}