import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { StorageService } from 'src/app/_services/front/storage.service';

@Injectable()
export class AuthGuard implements CanActivate {
    loading = false;
    constructor(
        private _router: Router,
        private _storageService: StorageService
    ) {}
    
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        // return true;
        let authData = JSON.parse(this._storageService.getDataFromStorage('authData'));
        if (authData === null) {
            this._router.navigate(['/customer/login'], { queryParams: { returnUrl: state.url } });
            return false;
        } else {
            let authData = JSON.parse(this._storageService.getDataFromStorage('authData'));
            if (authData.accessToken != '' && authData.accessToken != undefined) {
                return true;
            } else {
                this._router.navigate(['/customer/login'], { queryParams: { returnUrl: state.url } });
                return false;
            }
        }
    }
}