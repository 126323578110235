import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UrlMultilangugalService } from '../_services/front/url-multilangugal.service';

import { LocalizationComponent } from './localization.component';

const routes: Routes = [
  { path: 'zh-hans', "loadChildren": () => import('src/app/front/front.module').then(m => m.FrontModule),
  canActivateChild: [UrlMultilangugalService]
  },
  { path: '', "loadChildren": () => import('src/app/front/front.module').then(m => m.FrontModule),
  canActivateChild: [UrlMultilangugalService]

},
  {
    "path": "**",
    "redirectTo": "",
    "pathMatch": "full"
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LocalizationRoutingModule { }
