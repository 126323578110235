import { Injectable } from '@angular/core';
import { SwireConstants } from 'src/app/_services/front/constants';

declare let ga:any; // Declare ga as a function
declare let gtag:any; // Declare gtag as a function

@Injectable()
export class GoogleAnalyticsService {
   environment:any = '';
   constructor(
      private _swireConstants:SwireConstants) 
    {
       this.environment = this._swireConstants.appEnv;
    } 
    
  //create our event emitter to send our data to Google Analytics
   public sendGaData(arData: any) {
      ga('send', 'event', arData); 
   } 
   public sendGtagData(arData: any, szEventName: string) { 
         gtag('event', szEventName, arData);  
   }

   public sendCustomData(arData:any)
   { 
         gtag('event', arData.eventCategory, {
            'origin': arData.origin, 
            'destination': arData.destination, 
            'shareEmailAddress': arData.shareEmailAddress,  
            'shareMessage': arData.shareMessage,  
            'cargoType': arData.cargoType,  
            'commodityType': arData.commodityType,  
            'containerType': arData.containerType,  
            'dangerousGoods': arData.dangerousGoods,  
            'trackingNumber': arData.trackingNumber,  
            'trackingType': arData.trackingType,  
         });  
   } 
}